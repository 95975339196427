.whatsappbutton {
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: auto;
  height: 60px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: bounce 10s infinite;
  z-index: 5000000;
}

.whatsappbutton img {
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 0px 10px #00000081) drop-shadow(0px 0px 20px #000000);
}

.alertshow{
  display : none;  
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  height: 30px;  
  background-color: #fff;
  border-radius: 10px;
  transform: translateX(30px);   
}

.alertshowname{
  margin-top: 15px;
  margin-left: 10px; 
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

@media screen and (max-width: 500px) {
  .whatsappbutton {
    bottom: 60px;
    right: 22px;
    width: 50px;
    height: 50px;
  }
  .whatsappbutton img {
    width: 50px;
    height: 50px;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    filter: drop-shadow(0px 0px 10px #04ff2131)
      drop-shadow(0px 0px 20px #ffffff);
  }
  40% {
    transform: translateY(-10px);
    filter: drop-shadow(0px 0px 10px #04ff2162)
      drop-shadow(0px 0px 20px #ffffff5b);
  }
  60% {
    transform: translateY(-5px);
    filter: drop-shadow(0px 0px 10px #04ff21f2)
      drop-shadow(0px 0px 20px #ffffffd5);
  }
}

@keyframes rodar{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
