.MasterLinks {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.MasterLinks::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/links/background.png");
  background-size: cover;
  background-position: center;
  filter: blur(3px);
  z-index: -1;
}

/*DESKTOP VERSION*/

.RSimg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 50px;
}
.FotoRSImg {
  width: 100%;
  height: 100%;
}

.RSTittlex {
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 600;
  color: azure;
  text-shadow: #00000067 0px 0px 30px, #2bff00da 0px 0px 30px;
  text-align: center;
  margin-bottom: 0px;
  z-index: 1000000;
}

.RSsubtittlex {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: azure;
  text-shadow: #00000067 0px 0px 32px, #b5fda7da 0px 0px 10px;
  text-align: center;
  z-index: 1000000;
}

.RSbuttonsx {
  width: 250px;
  height: 50px;
  margin-bottom: 30px;
  border-radius: 22px;
  background-color: #007135;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
  color: azure;
  text-shadow: #00000067 0px 0px 32px, #b5fda760 0px 0px 10px;
  text-align: center;
  box-shadow: #0000005d 0px 0px 100px 5px;
  transition: 1s ease-in-out all;
  z-index: 500000;
}

.RSbuttonsx:hover {
  box-shadow: #ffffff 0px 0px 10px 1px;
}

/*MOBILE VERSION*/
@media screen and (max-height: 700px) {
  .RSimg {
    width: 100px;
    height: 100px;
  }
  .RSbuttonsx {
    margin-bottom: 10px;
    width: 200px;
    height: 40px;
  }
}

@media screen and (max-height: 800px) {
  .RSimg {
    width: 120px;
    height: 120px;
  }
  .RSbuttonsx {
    margin-bottom: 20px;
    width: 200px;
    height: 40px;
  }
}
