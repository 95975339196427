.lettersTexto {
  text-align: justify;
  color: #515151;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 1px;
  white-space: pre-line;
}

.lettersTexto span.show{
  color: #006006b8;
  font-size: 22x;
  font-weight: 600;
  text-shadow: 0px 0px 5px #c7ffcb;

}
