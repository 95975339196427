.BoxSocial {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  height: 1500px;
  margin-bottom: 30px;
  border: 1px solid #00000000;
  overflow: hidden;
}

.BoxSocialTittle {
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 50px;
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  word-spacing: 0px;
  color: #0c672b;
}

.BoxSocialContain {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 1200px;
}

.BoxSocialInstagram {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 80px;
}

.BoxSocialTwitter {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;  
}

.BoxSocialFacebook {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-left: 80px;  
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .BoxSocial {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    border: 1px solid #00000000;
    overflow: hidden;
  }
  .BoxSocialContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .BoxSocialInstagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 0px;
  }  
  .BoxSocialFacebook {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 0px;  
  } 
}
