.Navbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #0c672abe, #179d20b3);
  border-bottom: 2px solid #0c672a;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: -6px 10px 50px 5px #000000c2;
  height: 75px;
  width: 100%;
  transition: all 1s ease;
  z-index: 3000000;
}
.NavbarBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.BoxLogoBotonRS {
  display: inline;
  position: absolute;
  left: 15vw;
}
.BoxImgLogoRS {
  display: flex;
  position: static;
  align-items: center;
}
.LogoRS {
  height: 45px;
  margin-top: 8px;
  margin-left: 14px;
  cursor: pointer;
  transition: all 1s ease;
}
.LogoRS:hover {
  filter: drop-shadow(0px 0px 5px #d4ffb1e7);
}

.MenuRS {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 26vw;
}
.NaveRS {
  padding: 0px 14px;
  margin-right: 0px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #ffffffdb;
}
.NaveRS:hover {
  color: #fff;
  transition: 1s all;
  transform: scale(1.08);
  cursor: pointer;
  filter: drop-shadow(0px 0px 5px #ffffffd8);
}
.BotonRSweb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 18vw;
}

.BotonRSmobile {
  display: none;
}

/*VERSION DESKTOP*/
@media screen and (min-width: 501px) and (max-width: 1600px) {
  .BoxLogoBotonRS {
    display: inline;
    position: absolute;
    left: 5vw;
  }
  .MenuRS {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 16vw;
  }
  .BotonRSweb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 5vw;
  }
  .BotonRSmobile {
    display: none;
  }
}

/*VERSION MOBILE*/
@media screen and (min-width: 1px) and (max-width: 500px) {
  .NavbarBox {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .BoxLogoBotonRS {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: initial;
    width: 100%;
    margin-bottom: 5px;
  }

  .BoxImgLogoRS {
    display: flex;
    position: initial;
    align-items: center;
  }
  .LogoRS {
    height: 26px;
    margin-top: 0px;
    margin-left: 0px;
    cursor: pointer;
    transition: all 1s ease;
  }
  .LogoRS:hover {
    filter: drop-shadow(0px 0px 5px #d4ffb1e7);
  }

  .MenuRS {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    right: 0px;    
  }
  .NaveRS {
    padding: 0px 8px;
    margin-right: 0px;    
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    color: #ffffffdb;
  }
  .NaveRS:hover {
    color: #fff;
    transition: 1s all;
    transform: scale(1.08);
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px #ffffffd8);
  }
  .BotonRSweb {
    display: none;
  }

  .BotonRSmobile {
    display: flex;
  }
}
