.buttondown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;  
  background-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  transition: 0.2s ease all;
}

.buttondown:hover {
  background-color: #f5f5f5;
  transform: scale(1.2);  
}
