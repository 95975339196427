.BoxContador {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(45deg, #179d2094, #76ba1cb4);
  border-radius: 16px;
  /* box-shadow: 0px 5px 20px 1px #000000e8; */
  /* backdrop-filter: blur(5.7px); */
  /* -webkit-backdrop-filter: blur(5.7px); */
  /* border: 3px solid #007135; */
  width: 170px;
  height: 60px;
  margin-top: 30px;
  left: -5px;
  /* top: 80%;
  left: 5%; */
  /* transform: translateY(-50%); */
  /* z-index: 1000000; */
}

.Contador{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-align: center;
    margin-bottom: -30px;
}

.Count{    
    font-size: 28px;
    font-family: "Poppins";
    font-weight: 800;
    color: #fff;
    word-spacing: 12px;
}


/*
background: rgba(255, 255, 255, 0.45);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.7px);
-webkit-backdrop-filter: blur(5.7px);
border: 1px solid rgba(255, 255, 255, 0.42);*/
