.BoxRedes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
}

.BoxRed {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  margin-top: 15px;
  transition: 0.3s all ease-in-out;
}

.BoxRed:hover {
  transform: scale(1.3);
}

.BoxSigue {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 160px;
  height: 40px;
  background-color: #ffffff;
  text-align: center;
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 800;
  border-radius: 16px 16px 0px 0px;
  transform: rotateZ(270deg) translateY(3.3vw);
}

.ImgRed {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  transition: 0.3s all ease-in-out;
  filter: drop-shadow(0px 0px 10px #00000081) drop-shadow(0px 0px 20px #000000);
}

.ImgRed:hover {
  filter: drop-shadow(0px 0px 10px #ffffff);
}

/*DESKTOP VERSION*/
@media screen and (min-width: 501px) and (max-width: 1500px) {
  .BoxRed {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    margin-top: 15px;
    right: 10px;
    transition: 0.3s all ease-in-out;
  }
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .BoxRed {
    width: 100%;
    position: relative;
    top: -60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    justify-items: center;
    margin: initial;
    margin-top: 15px;
    transition: 0.3s all ease-in-out;
  }
  .ImgRed {    
    margin: 0px;
    margin-right: 20px;    
  }
  .BoxSigue {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 140px;
    height: 50px;
    margin-top: 20px;
    background-color: #ffffff;
    text-align: center;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 800;
    border: 1px solid #13ba00a4;
    border-radius: 16px 0px 0px 0px;
    transform: rotateZ(0deg) translateY(0vw) translateX(0vh);
    box-shadow: 0px 0px 15px 1px #0000004d;
  }
}
