.MasterBoxBlog {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.Noticia {  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 100px;
  width: 1300px;
  height: auto;
}

.Separador {
  margin-left: 15px;
  height: 300px;
  border: 1px solid #00000041;
  box-shadow: 3px 0px 10px 1px #00000041;
}

.FotoMedios {
  border-radius: 42px;  
  margin-left: 0px;
  margin-right: 20px;
  width: 400px;
  height: 400px;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 5px 15px 50px 1px rgba(0, 0, 0, 0.5);
}
.FotoImgMedios {    
  height: 100%;
  object-fit: cover; 
}

.NoticeBox {
  display: flex;
  flex-direction: column;
  width: 800px;
  height: auto;
  margin-left: 50px;
}

.Tittle {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007c3a;
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 1px;
  word-spacing: 0.2px;
  line-height: 30px;
}

.Fecha {
  color: #000000;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  word-spacing: 1px;
}

.NoticeBoxTexto{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;  
}

.Notice {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000000;
  font-family: 'Lexend';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: 30px;
  letter-spacing: 0.8px;
  word-spacing: 0.2px;
}

.NoticeBottomMore {
  width: 90px;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #277425;
  border-radius: 10px;
  color: #277425;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.NoticeBottomMore:hover {
  background-color: #23a455;
  color: azure;
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 1px #000000d8;
  filter: drop-shadow(0px 0px 5px #000000d8);
}

.reportero {
  color: #000000;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 200;
  text-align: left;
  letter-spacing: 0.5px;
  word-spacing: 0.5px;
}

.Instagram {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .Noticia {
    width: 350px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .Separador {
    display: none;
  }
  .NoticeBox {
    width: 300px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .Tittle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
    text-align: justify;
    letter-spacing: 0.1px;
    word-spacing: 0.1px;
    line-height: 26px;
  }
  .Fecha {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0.5px;
    word-spacing: 1px;
  }
  .Notice {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
    word-spacing: 0.1px;
  }
  .FotoMedios {
    border-radius: 42px;  
    margin-left: 0px;
    margin-right: 20px;
    width: 300px;
    height: 300px;
    background-color: tra;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 5px 15px 50px 1px rgba(0, 0, 0, 0.5);
  }
  .reportero {
    color: #000000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
}
