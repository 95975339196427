.MasterBoxNoticias {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .Banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 320px;
    font-family: "Bree Serif";
    font-style: normal;
    font-size: 72px;
    font-weight: 500;
    text-align: justify;
    color: aliceblue;
    letter-spacing: 10px;
    word-spacing: 0.2px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/noticias/noticia.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 42%;
  }
  
  .overlay{
    margin-top: 100px;
  }