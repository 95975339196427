/*Matriz principal de los semilleros*/
.MasterBoxContainerSemilla {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}
.MasterBoxSemilla {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.BannerSemilla {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  padding-top: 100px;
  font-family: "Bree Serif";
  font-style: normal;
  font-size: 72px;
  font-weight: 500;
  letter-spacing: 10px;
  text-align: justify;
  text-shadow: 0px 2px 4px #000000;
  color: aliceblue;
  letter-spacing: 10px;
  word-spacing: 0.2px;
  background-image: linear-gradient(180deg, #00000060, #ffffff00),
    url("../../assets/banner/semilleros.gif");
  background-size: cover;
  background-repeat: no-repeat;
}
.TituloSemilla {
  font-family: "Bree Serif";
}
.MenuButtonsSemilla {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-45%);
  width: auto;
  height: auto;
  background-color: transparent;
  z-index: 3000000;
}
.BotonSemilla {
  position: relative;
  width: 300px;
  height: 50px;
  background: linear-gradient(180deg, #0c672a, #179d20);
  border-radius: 9px 0px 0px 9px;
  color: white;
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 16px;
  transition: all 0.3s ease-in-out;
}
.BotonSemilla:hover {
  background: linear-gradient(210deg, #138539, #19bf24);
  filter: drop-shadow(0px 0px 5px #000000d8);
  text-shadow: 0px 0px 10px #ffffff7a;
  transform: translateX(-26px) scale(1.1);
  transition: all 0.3s ease-in-out;
}

/*NOTICIAS DE LOS SEMILLEROS*/
.MasterBoxSemilleros {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.BoxNotice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateX(10vw);
}
.Noticia {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.Foto {
  border-radius: 42px;
  margin-left: 0px;
  margin-right: 20px;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 5px 15px 50px 1px rgba(0, 0, 0, 0.5);
}
.FotoImg {
  height: 100%;
  object-fit: cover;
}
.Separador {
  margin-left: 15px;
  height: 400px;
  border: 1px solid #00000041;
  box-shadow: 3px 0px 10px 1px #00000041;
}
.NoticeBox {
  display: flex;
  flex-direction: column;
  width: 700px;
  height: auto;
  margin-left: 50px;
}
.NoticeBoxTexto {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.NoticeBottomMore {
  width: 90px;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #277425;
  border-radius: 10px;
  color: #277425;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.NoticeBottomMore:hover {
  background-color: #23a455;
  color: azure;
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 1px #000000d8;
  filter: drop-shadow(0px 0px 5px #000000d8);
}
.Tittle {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007c3a;
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
  text-align: justify;
  line-height: 32px;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.Fecha {
  color: #000000;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.Notice {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000000;
  font-family: "Lexend";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: 30px;
  letter-spacing: 0.8px;
  word-spacing: 0.2px;
}

/*RESPONSIVE*/
/*DESKTOP VERSION*/
@media screen and (min-width: 501px) and (max-width: 1500px) {
  .MenuButtonsSemilla {
    margin: 0 auto;
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    top: unset;
    bottom: 10px;
    right: 0px;
    left: 0px;
    transform: translateY(0px);
    width: 900px;
    height: auto;
    background-color: transparent;
    z-index: 3000000;
  }
  .BotonSemilla {
    position: relative;
    width: 120px;
    height: 35px;
    background-color: #ffffff;
    border-radius: 30px;
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }
  .BotonSemilla:hover {
    background-color: #005f2d;
    transform: translateX(0px) scale(5);
    filter: drop-shadow(0px 0px 5px #000000d8);
    text-shadow: 2px 2px 4px #d4ffb1e7;
    transition: all 0.3s ease-in-out;
  }
  .Foto {
    border-radius: 42px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 300px;
    height: 300px;
    background-color: tra;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 2px 10px 10px 1px rgba(0, 0, 0, 0.5);
  }
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .BannerSemilla {
    background-size: cover;
    background-position: 40%;
    font-size: 52px;
    font-weight: 500;
    text-shadow: 0px 2px 4px #000000;
  }
  .MasterBoxSemilleros {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .MenuButtonsSemilla {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    top: unset;
    bottom: 50px;
    right: 0px;
    left: 0px;
    transform: translateY(0px);
    width: 350px;
    height: auto;
    background-color: transparent;
    z-index: 3000000;
  }
  .BotonSemilla {
    position: relative;
    width: 100px;
    height: 25px;
    background: #007c3a;
    border-radius: 9px 9px 9px 9px;
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
  }
  .BotonSemilla:hover {
    background: #005f2d;
    transform: translateX(0px) scale(1.05);
    text-shadow: 2px 2px 4px #d4ffb1e7;
    transition: all 0.3s ease-in-out;
  }
  .Foto {
    border-radius: 42px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 300px;
    height: 300px;
    background-color: tra;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 2px 10px 10px 1px rgba(0, 0, 0, 0.5);
  }
  .Noticia {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .Separador {
    display: none;
  }
  .BoxNotice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateX(0vw);
  }

  .NoticeBox {
    width: 300px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .Tittle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
    text-align: justify;
    letter-spacing: 0.1px;
    word-spacing: 0.1px;
    line-height: 26px;
  }
  .Fecha {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0.5px;
    word-spacing: 1px;
  }
  .Notice {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
    word-spacing: 0.1px;
  }
}
