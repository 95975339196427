.Master {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  text-align: center;
}
.Fotobanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #007135;
  margin: 0 auto;
  background-image: url("../../assets/banner/foto.png");
  background-size: 145%;
  background-repeat: no-repeat;
  background-position: 10% 35%;
}
.FotoShadow {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #044b0967, transparent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Buttondwn {
  position: relative;
  background-color: transparent;
  bottom: -18vh;
}
.BoxRedesFixed {
  position: fixed;
  justify-content: center;
  align-items: center;
  justify-items: center;
  right: 0px;
  bottom: 0px;
  transform: translateX(-3vw) translateY(-40vh);
  z-index: 2000000;
}

.NavidadArbol {
  width: 500px;
  height: 500px;
  transform: scale(0.7) translateX(-700px) translateY(80px);
}

/*DESKTOP VERSION*/
@media screen and (min-width: 501px) and (max-width: 1500px) {
  .BoxRedesFixed {
    position: fixed;
    justify-content: center;
    align-items: center;
    justify-items: center;
    right: 0px;
    bottom: 0px;
    transform: translateX(-2.8vw) translateY(-20vh);
    z-index: 2000000;
  }
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .Fotobanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #007135;
    margin: 0 auto;
    background-image: url("../../assets/foto.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 35%;
  }
  .FotoShadow {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #044b0967, transparent);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Buttondwn {
    position: relative;
    background-color: transparent;
    bottom: -28vh;
  }
  .BoxRedesFixed {
    position: fixed;
    justify-content: center;
    align-items: center;
    justify-items: center;
    bottom: 0;
    right: 0;
    transform: translateX(0vw) translateY(0vh);
    z-index: 2000000;
  }

  .NavidadArbol {
    width: 500px;
    height: 500px;
    transform: scale(0.5) translateX(-150px) translateY(0%);
  }
}
