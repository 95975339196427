.Master{
    background-color: #007135;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.Columms{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;

}

.BoxColumms{
    display: flex;
    width: 300px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-bottom: 20px;
}

.logo{
    width: 160px;
    margin-bottom: 10px;
    margin-left: -10px;    
}

.Textp{
    font-size: 14px;
    color: #eaeef3;
    margin-bottom: 30px;
}

.buttom{
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Poppins';
    border: 1px solid #fff;    
    padding: 8px 20px;
    border-radius: 30px;
    background-color: #007135;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}

.buttom:hover{
    background-color: #54AE32;
    transition: 0.5s all;
}

.titulo{
    padding: 0;
    margin: 0px 0px 20px 0px;
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Poppins';
    color: #FFFFFF;
}

.datosx{
    font-size: 16px;
    color: #eaeef3;
    margin-top: 5px;
    font-family: 'Poppins';    
}

.datosx:hover{
    color: #b8edbf;
    transition: 0.5s all;    
}

.wpp{    
    display: flex;
    flex-direction: row;
    color: #eaeef3;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Poppins';
}

.rutax{
    margin-top: 10px;
    margin-left: -5px;
    display: flex;
    color: #eaeef3;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Poppins';    
}

.rutax:hover{
    color: #61CE70;
    transition: 0.5s all;
    font-size: 22px;
    
}

.datosy{
    font-size: 16px;
    font-family: 'Poppins';
    color: #eaeef3;
    margin-top: 5px;    
}

.datosy:hover{
    color: #b8edbf;
    transition: 0.5s all;    
}

.redes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
}

.tik{
    width: 30px;
    transition: 0.3s ease all;
}

.tik:hover{
    transform: scale(1.5);    
    
}

.Copy{
    background-color: #00411E;
    padding: 40px 40px 40px 40px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.boxcopy{
    color: #FFFFFF;
}

@media screen and (max-width: 1280px){
    .Master{        
        display: flex;
        flex-direction: column;
    }
    .Columms{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .BoxColumms{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    
}