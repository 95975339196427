.MasterBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  margin-top: 50px;  
  background-color: white;
}

.TittleCards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.TittleValoresCartas{
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  color: #0C672B;
  margin-bottom: 0px;
}

.SubTittleValoresCartas{
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 400;
  color: #000000;
}

.Cartas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  height: 380px;
  margin: 20px 30px;
  background-color: #ffffff;
  /* background: linear-gradient(deg, #179d208f, #76BA1C); */
  border-radius: 10px;
  box-shadow: 0px 0px 40px 1px #00000030;
  transition: all 1s;  
}

.Card:hover {
  transform: scale(1.1);
}

.CardImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.ImgCard {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.CardText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 10px;
}

.tittle {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  color: #007c3a;
  margin-bottom: 10px;
}

.text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  text-align: center;
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .MasterBox {
    display: block;
    height: auto;   
  }

  .TittleCards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;    
  }  
  .TittleValoresCartas{
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
    color: #0C672B;
    margin-bottom: 0px;
  }  
  .SubTittleValoresCartas{
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }

  .Cartas {    
    flex-direction: column;    
  }
  .Card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: 300px;
    margin: 0px;
    border-radius: 10px;
    box-shadow: 5px 5px 50px 1px rgba(0, 0, 0, 0.311);
    transition: all 1s;
    margin-top: 30px;
  }
  .CardImg {    
    width: 120px;
    height: 120px;    
  }
  .CardText {
    display: flex;       
    width: 260px;
    height: auto;
    padding: 0px;
    margin: 0px;
  }
  .tittle {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    color: #007c3a;
    margin-bottom: 5px;
  }  
  .text {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    text-align: center;
  }
}

