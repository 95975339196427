.UneteRS {
  width: 130px;
  height: 36px;
  display: flex;
  font-family: "Poppins";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.UneteRS:hover {
  background-color: #23a455;
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 1px #ffffffd8;
  filter: drop-shadow(0px 0px 5px #ffffffd8);
}

/*VERSION MOBILE*/
@media screen and (min-width: 1px) and (max-width: 500px) {
  .UneteRS {
    width: 100px;
    height: 30px;
    font-weight: 700;
    font-size: 12px;
    margin-right: 10px;
  }
}
