.BannerFondoPublic {
  margin: 0 auto;
  background-color: #007135;
  padding-top: 60px;
  position: relative;
}

.ImgPublicidad {
  width: 100%;
  object-fit: cover;
}

.ImgPublicidadMovil{
  display: none;
}

.PublicButtom {
  width: 300px;
  height: 70px;
  display: flex;
  font-family: "Poppins";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 42px;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  position: relative;
  bottom: 28vh;
  right: -62vw;
  
}

.PublicButtom:hover {
  background-color: #23a455;
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 1px #ffffffd8;
  filter: drop-shadow(0px 0px 5px #ffffffd8);
}

@media screen and (max-width: 500px) {
  .BannerFondoPublic {    
    padding-top: 20px;    
  }
  
  .ImgPublicidad {
    display: none;
  }
  
  .ImgPublicidadMovil{
    display: flex;
    width: 100%;
  }
  
  .PublicButtom {
    width: 120px;
    height: 30px;
    display: flex;
    font-family: "Poppins";
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #fff;
    border-radius: 30px;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    position: relative;
    bottom: 16vh;
    left: 10vw;
    
  }
  
  .PublicButtom:hover {
    background-color: #23a455;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px 1px #ffffffd8;
    filter: drop-shadow(0px 0px 5px #ffffffd8);
  }

}