.MasterFrase {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.Frasebanner {
  width: 800px;
  position: relative;
  transform: translateX(-300px) translateY(-100px);
}

.Frase {
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 600;
  /* text-shadow: #a1a1a13c 5px 5px 10px; */
  text-align: left;
  color: #ffffff;
  background-color: transparent;
  padding: 10px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 75px;
}

.FraseDestacada{
  font-weight: 800;
  font-size: 56px;
  color: #0B5127;
  background-color: #75B02D;
}

/*DESKTOP VERSION*/
@media screen and (min-width: 501px) and (max-width: 1500px) {
  .Frasebanner {
    width: 800px;
    position: relative;
    transform: translateX(-300px) translateY(180px);
  }
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .Frasebanner {
    width: 100%;
    position: relative;
    transform: translateX(10px) translateY(-160px);
  }
  .Frase {
    font-family: "Poppins";
    font-size: 26px;
    font-weight: 600;    
    text-align: left;
    color: #ffffff;
    background-color: transparent;
    padding: 10px;
    letter-spacing: 1px;
    word-spacing: 2px;
    line-height: 36px;
  }
  .FraseDestacada{
    font-weight: 800;
    font-size: 28px;
    color: #0B5127;
    background-color: #75B02D;
  }
}
