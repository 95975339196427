.EsloganMaster {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  top: 10%;
  right: 22%;
  transform:translateY(10px);  
}

.EsloganBox {
  width: 100%;
}
.EsloganTexto {
  font-family: "wellcomehome";
  font-size: 72px;
  font-weight: 600;
  color: #0c672b;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.626), 0px 0px 10px rgba(255, 255, 255, 0.878);
}

/*VERSION MOBILE*/
@media screen and (max-width: 500px) {
  .EsloganMaster {
    width: 250px;
    position: absolute;
    top: 65%;
    left: 5%;
  }
  .EsloganTexto {
    font-size: 54px;
    text-align: left;
  }
}
