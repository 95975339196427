.SliderMaster {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 50px;
  margin-top: 0px;
}

.TittleEventos {
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 50px;
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  word-spacing: 0px;
  color: #0c672b;
}

.buttond {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  margin: 30px;
  padding: 30px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  transition: 0.2s ease all;
}

.buttond:hover {
  background-color: #f5f5f5;
  transform: scale(1.2);
}

.Matter {
  margin: 0 auto;
  width: 1500px;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MatterContent {
  margin: 0 auto;
  width: auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.EventoFebrero {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(0.91) translateY(20px) translateX(-160px); */
  object-fit: cover;
}

.EventoMarzo {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(1.2) translateY(20px); */
  object-fit: cover;
}

.EventoAbril {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(1.1) translateY(110px); */
  object-fit: contain;
}

.EventoMayo {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(1.2); */
  object-fit: contain;
}

.EventoJunio {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(0.5) translateX(-2000px) translateY(300px); */
  object-fit: contain;
}

.EventoJulio {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(0.7) translateX(-600px) translateY(-100px); */
  object-fit: contain;
}

.EventoAgosto {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(0.6) translateX(-300px) translateY(-100px); */
  object-fit: contain;
}

.EventoSeptiembre {
  margin: 0 auto;
  width: 1200px;
  /* transform: scale(0.3) translateX(-200vw) translateY(-20vh); */
  object-fit: contain;
}

.EventoOctubre {
  margin: 0 auto;
  width: 1200px;  
  object-fit: contain;
}

.EventoNoviembre {
  margin: 0 auto;
  width: 1200px;  
  object-fit: contain;
}

.TextEvent {
  margin: 0 auto;
  margin-top: 40px;
  color: #000000;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 2px;
}

.FechaEvent {
  margin: 0 auto;
  margin-top: 10px;
  color: #000000;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 2px;
}

.TextImg {
  margin: 0 auto;
  width: 1000px;
  margin-top: 10px;
  color: #000000;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  text-align: justify;
  letter-spacing: 0.5px;
  word-spacing: 1px;
}

@media screen and (max-width: 500px) {
  .SliderMaster {
    padding: 30px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .TittleEventos {
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 0px;
    color: #0c672b;
  }
  .Matter {
    margin: 0 auto;
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;   
  }
  .MatterContent {
    margin: 0 auto;
    width: auto;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .TextEvent {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;    
    color: #000000;
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
    line-height: 20px;
  }
  .FechaEvent {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;    
    color: #000000;
    font-family: "Poppins";
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
    line-height: 20px;
  }
  .TextImg {
    margin: 0 auto;    
    width: auto;
    margin-top: 0px;
    color: #000000;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    letter-spacing: 1.5px;
    word-spacing: 1px;
    line-height: 20px;
  }

  .EventoFebrero {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }
  .EventoMarzo {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }
  .EventoAbril {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }
  .EventoMayo {
    margin: 0 auto;
    width: 300px;
    object-fit: contain;
  }
  .EventoJunio {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }  
  .EventoJulio {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }  
  .EventoAgosto {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }  
  .EventoSeptiembre {
    margin: 0 auto;
    width: 300px;    
    object-fit: contain;
  }
}
