.Master {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  text-align: center;
}

.Buttondwn {
  margin-bottom: 50px;
}

/*---------------------- SECCION 1 -----------------------------------------------------------------*/

.lettersTitulo {
  color: #12642e;
  font-family: "Poppins";
  font-size: 45px;
  font-weight: 700;
  text-transform: none;
  line-height: 55px;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 30px;
}

.Bio1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: auto;
  width: 100%;
  transition: all 1s;
  text-align: center;
}

.BoxBio1 {
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 50px;
}

.BoxText {
  width: 700px;
  height: auto;
  background-color: transparent;
  transition: all 1s;
  text-align: center;
}

.tittle1 {
  color: #12642e;
  font-family: "Poppins";
  font-size: 45px;
  font-weight: 700;
  text-transform: none;
  line-height: 55px;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 30px;
}

.text1 {
  text-align: justify;
  color: #515151;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 1px;
}

p {
  margin-bottom: 1em;
}

.BioImgBox1 {
  border-radius: 42px;
  margin-left: 70px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 5px 15px 50px 1px rgba(0, 0, 0, 0.5);
}

.BioImg1 {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/*---------------------- SECCION 2 -----------------------------------------------------------------*/

.Bio2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #007c3a;
  height: auto;
  width: 100%;
  transition: all 1s;
  text-align: center;
}

.BoxBio2 {
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.BoxText2 {
  width: 700px;
  height: auto;
  background-color: transparent;
  transition: all 1s;
  text-align: center;
}

.tittle2 {
  color: #ffffff;
  font-family: "Poppins";
  font-size: 45px;
  font-weight: 700;
  text-transform: none;
  line-height: 55px;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 30px;
}

.text2 {
  text-align: justify;
  color: #fffffffc;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 4px;
}

p {
  margin-bottom: 1em;
}

.BioImgBox2 {
  border-radius: 16px;
  margin-right: 70px;
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: -15px 25px 50px 5px rgba(0, 0, 0, 0.5);
}

.BioImg2 {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/*---------------------- SECCION 3 -----------------------------------------------------------------*/

.Bio3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: auto;
  width: 100%;
  transition: all 1s;
  text-align: center;
}

.BoxBio3 {
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 120px;
}

.BioImgBox3 {
  border-radius: 32px;
  margin-left: 70px;
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 15px 25px 50px 5px rgba(0, 0, 0, 0.5);
}

.BioImg3 {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.BoxText3 {
  display: flex;
  width: 700px;
  height: auto;
  background-color: transparent;
  transition: all 1s;
  text-align: center;
  flex-direction: column;
  align-items: flex-end;
}

.tittle3 {
  color: #12642e;
  font-family: "Poppins";
  font-size: 45px;
  font-weight: 700;
  text-transform: none;
  line-height: 55px;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin-bottom: 30px;
  transform: translateX(-80px);
}

.text3 {
  width: 650px;
  text-align: justify;
  color: #515151;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 1px;
}

/*---------------------- RESPONSIVE -----------------------------------------------------------------*/

/*DESKTOP VERSION*/
@media screen and (min-width: 431px) and (max-width: 1500px) {
  .BoxBio1 {
    display: flex;
    flex-direction: row;
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin-top: 140px;
    margin-bottom: 50px;
  }
  .BoxText {
    width: 500px;
    height: auto;
    background-color: transparent;
    transition: all 1s;
    text-align: center;
  }
  .tittle1 {
    color: #12642e;
    font-family: "Poppins";
    font-size: 45px;
    font-weight: 700;
    text-transform: none;
    line-height: 55px;
    letter-spacing: 0px;
    word-spacing: 0px;
    margin-bottom: 30px;
  }
  .text1 {
    text-align: justify;
    color: #515151;
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
  }
  .BioImgBox1 {
    border-radius: 42px;
    margin-left: 70px;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 5px 15px 50px 1px rgba(0, 0, 0, 0.5);
  }
  .BioImg1 {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .BoxBio2 {
    display: flex;
    flex-direction: row;
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .BoxText2 {
    width: 500px;
    height: auto;
    background-color: transparent;
    transition: all 1s;
    text-align: center;
  }
  .tittle2 {
    color: #ffffff;
    font-family: "Poppins";
    font-size: 45px;
    font-weight: 700;
    text-transform: none;
    line-height: 55px;
    letter-spacing: 0px;
    word-spacing: 0px;
    margin-bottom: 30px;
  }
  .text2 {
    text-align: justify;
    color: #fffffffc;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 1px;
    word-spacing: 4px;
  }
  .BioImgBox2 {
    border-radius: 16px;
    margin-right: 70px;
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: -15px 25px 50px 5px rgba(0, 0, 0, 0.5);
  }
  .BioImg2 {
    width: 100%;
    height: auto;
    transform: scale(2) translateX(-10px);
    object-fit: contain;
  }

  .BoxBio3 {
    display: flex;
    flex-direction: row;
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .BoxText3 {
    display: flex;
    width: 500px;
    height: auto;
    background-color: transparent;
    transition: all 1s;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .tittle3 {
    color: #12642e;
    font-family: "Poppins";
    font-size: 45px;
    font-weight: 700;
    text-transform: none;
    line-height: 45px;
    letter-spacing: 0px;
    word-spacing: 0px;
    margin-bottom: 30px;
    transform: translateX(0px);
  }
  .text3 {
    width: auto;
    text-align: justify;
    color: #515151;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
    word-spacing: 0.5px;
  }
  .BioImgBox3 {
    border-radius: 32px;
    margin-left: 70px;
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 15px 25px 50px 5px rgba(0, 0, 0, 0.5);
  }
  .BioImg3 {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

/*VERSION MOBILE*/
@media screen and (max-width: 430px) {
  .Bio1 {
    margin-top: 80px;
  }
  .BoxBio1 {
    width: 350px;
    flex-direction: column-reverse;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .BioImgBox1 {
    border-radius: 32px;
    margin-left: 0px;
    width: 200px;
    height: 260px;
    margin-bottom: 20px;
  }
  .BoxText {
    width: 320px;
  }
  .tittle1 {
    color: #12642e;
    font-family: "Poppins";
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 20px;
  }
  .text1 {
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    word-spacing: 0.05em;
  }

  .BoxBio2 {
    width: 350px;
    flex-direction: column-reverse;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .BoxText2 {
    width: 320px;
  }
  .tittle2 {
    font-size: 36px;
    font-weight: 700;
    text-transform: none;
    line-height: 38px;
    letter-spacing: 0px;
    word-spacing: 0px;
    margin-bottom: 20px;
  }
  .text2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.2px;
    word-spacing: 0.2px;
  }
  .BioImgBox2 {
    border-radius: 32px;
    margin-left: 0px;
    width: 300px;
    height: 260px;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 0px;
  }
  .BioImg2 {
    width: 100%;
    height: auto;
    transform: scale(2.2) translateX(-10px);
    object-fit: contain;
  }

  .BoxBio3 {
    display: flex;
    flex-direction: column-reverse;
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .BioImgBox3 {
    border-radius: 32px;
    margin-left: 0px;
    margin-top: 0px;
    width: 320px;
  }
  .BoxText3 {
    display: flex;
    width: 350px;
    height: auto;
    background-color: transparent;
    transition: all 1s;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .tittle3 {
    color: #12642e;
    font-family: "Poppins";
    font-size: 45px;
    font-weight: 700;
    text-transform: none;
    line-height: 55px;
    letter-spacing: 0px;
    word-spacing: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    transform: translateY(0px);
  }
  .text3 {
    width: 320px;
    text-align: justify;
    color: #515151;
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    word-spacing: 0.01em;
  }
}
